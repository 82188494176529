'use client';
import { useEffect, useRef, useState } from 'react';
import { XCloseIcon } from '../ui/icons/icons';
import { deleteCookie, getCookie } from 'cookies-next';

type Props = {
  cookieName: string;
};

type Toast = { message: string; position: string; duration?: number };

const parseCookie = (cookieName: string): Toast => {
  const cookie = getCookie(cookieName);
  const toast: Toast = { message: '', position: '' };

  if (typeof cookie === 'string') {
    try {
      const parsedCookie = JSON.parse(cookie);
      toast.message = parsedCookie.message;
      toast.position = parsedCookie.position;
      toast.duration = parsedCookie.duration;
    } catch (err) {
      toast.message = cookie;
      toast.position = 'bottom-right';
    }
  }

  return toast;
};

export const ToastAlert: React.FC<Props> = ({ cookieName }) => {
  const [toast, setToast] = useState<Toast | undefined>();
  const [hide, setHide] = useState('hidden');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleHide = () => {
    setHide('hidden');
    deleteCookie(cookieName);
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    const toast = parseCookie(cookieName);
    setToast(toast);
    setHide(toast.message ? '' : 'hidden');

    if (toast?.duration) {
      timeoutRef.current = setTimeout(() => handleHide(), toast.duration);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      className={`${hide}
    fixed z-10 flex h-[36px] items-center rounded bg-green600 p-3 pr-1 text-sm font-semibold leading-5 text-white md:h-[44px]
    ${toast?.position === 'top-right' ? 'left-[16px] right-[16px] top-[50px] md:left-[unset] md:right-[24px] md:top-[88px]' : ''}
    ${toast?.position === 'bottom-right' ? 'right[16px] md:right[24px] bottom-[24px]' : ''}`}
      data-testid="toast"
    >
      <div data-testid="message">{toast?.message}</div>
      <div
        role="none"
        data-testid="closeButton"
        className="ml-auto cursor-pointer p-2 md:ml-2"
        onClick={() => handleHide()}
      >
        <XCloseIcon width={16} height={16} />
      </div>
    </div>
  );
};
